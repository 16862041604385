.helpDiv{
    text-align: center;
    position: absolute;
    width: 86%;
    height: 85%;
    box-shadow: -3px 3px 6px #00000029;
    border-radius: 25px;
    bottom: 0;
    margin-left: 7%;
}

.helpTopHalf{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    height: 7%;
    border-radius: 25px 25px 0 0;
    box-shadow: 0 4px 2px -2px gray;
    
}

.helpBotHalf{
    background: #F9F9F9 0% 0% no-repeat padding-box;
    height: 93%;
}

.helpSearch{
    padding-top: 1.75%;
    height: 15%;
}

.helpLeft{
    width: 25%;
    height: 85%;
    float: left;
}

.helpRight{
    width:75%;
    height:85%;
    float:right;
}

.logoText{
    font-family: CustomFont;
    font-size: 2em;
    color: black;
}

.helpContainer{
    font-size: 1.25em;
    cursor: pointer;
}

@font-face{
    font-family: CustomFont;
    src: url(../../assets/fonts/sofiaprolight-webfont.woff);
}