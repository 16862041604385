.helpDiv{
    text-align: center;
    position: absolute;
    width: 86%;
    height: 85%;
    box-shadow: -3px 3px 6px #00000029;
    border-radius: 25px;
    bottom: 0;
    margin-left: 7%;
}

.helpTopHalf{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    height: 7%;
    border-radius: 25px 25px 0 0;
    box-shadow: 0 4px 2px -2px gray;
}

.helpBotHalf{
    background: #F9F9F9 0% 0% no-repeat padding-box;
    height: 93%;
}

.helpLeft{
    width: 25%;
    height: 85%;
    float: left;
}

.helpRight{
    width:75%;
    height:85%;
    float:right;
}

.logoText{
    font-family: CustomFont;
    font-size: 2em;
    color: black;
}

.helpContainer{
    font-size: 1.25em;
    cursor: pointer;
}

.surveyAdminView{
    background: #F9F9F9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 30px;

    width: 85%; 
    height: 83%;

    margin-left: 7.5%;

    position:absolute;
    padding: 1em;
    overflow-y: auto;
}

.surveyButtons{
    padding-top: 1em;
}

.editFormHeaders{
    font-weight: bold;
    font-size: 1.1em;
    text-align: center;
    margin: 0;
}


.filterViewAdmin{
    position: relative;
    background: #F9F9F9;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 30px;
    text-align: center;
    top: 25%;
    left: 25%;
    position: absolute;
    width: 50%;
}

.filterHeaderAdmin{
    width: 100%;
    height: 10%;
    text-align: center;
    font-size: 3em;
}

.filterBodyAdmin{
    width: 100%;
    height: 90%;
    padding: 1% 5% 1% 5%
}

.HelpEditingDiv{
    background: #F9F9F9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 30px;
    left: 20%;
    position: relative;
    height: 30%;
    width: 60%;
    margin-bottom: 2em;
    padding: 0.5em;
}

.HelpEditingDiv_Title{
    font-size: 2em;
    padding: 1em;
    padding: 0.5em;

}
.HelpEditingDiv_List{
    overflow-y: hi;
    height: 75%
}

.HelpEditingDiv_Button{
    height: 2%
}

.questLeft{
    padding: 1em;
    float:left;
    width:50%;
}

.questRight{
    padding: 1em;
    float:right;
    width:50%;
}

.adminDelConfirm{
    position: absolute;
    background: #F9F9F9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 30px;
    overflow: hidden;
    width: 40%;
    left: 30%;
    top: 20%;
}

.adminDelWrap{
    margin: 2%
}

.adminDelTitle{
    width: 100%;
    padding-top: 4%;
    font-size:large;
    font-weight: bold;
}

.adminDelSubTitle{
    padding-top: 5%;
    font-size:xx-large;
    font-weight: bolder;
}

@font-face{
    font-family: CustomFont;
    src: url(../../assets/fonts/sofiaprolight-webfont.woff);
}