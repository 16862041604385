html,
body {
  overflow: auto;
  background: #e1e1e1 0% 0% no-repeat padding-box;
  box-shadow: inset 0 0 15px #00000085;
}

.GDPR {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
}

.GDPRTitle {
  text-align: center;
  width: 100%;
  height: 15%;
  border: solid;
  border-color: #003769;
  border-width: 0px 0px 3px 0px;
}

.GDPRButton {
  text-align: center;
}

.GDPRButton .button {
  margin-bottom: 1.5rem;
}

.headerMain {
  height: 75px;
  opacity: 0.91;
  background-image: linear-gradient(138deg, #00aae1, #003769);
}

.headerLogo {
  height: 90%;
}

.container {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.headerTitle {
  float: left;
  padding-top: 0.4em;
  padding-left: 3%;
  font-family: CustomFont;
  font-size: 2.5em;
  color: white;
}
