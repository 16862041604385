:root {
  --orange: #f48800;
  --red: #eb1e23;
  --orangeRed: #e95027;
  --grey: #eae5e5;
  --midGrey: #d6d1d1;
  --black: #3d3d3d;
  --white: #f9f9f9;
}

p,
form,
.ui.checkbox label {
  font-family: CustomFont;
  color: var(--black);
}
.formField p {
  font-weight: bold;
  font-size: 1em;
}
.formTitle {
  padding-bottom: 0.5em;
}

.questDiv {
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
  clear: both;
  height: 80vh;
  display: flex;
  flex-direction: column;
}

.questHeader {
  display: flex;
  align-items: center;
  height: 60px;

  padding: 1% 10% 1% 10%;
}

/* Progress of answered questions */
.ui.blue.progress .bar {
  height: 16px;
  align-self: center;
  margin: 2px 2px 2px 2px;
  border-radius: 17px;
}

/* Progress of unanswered questions */
#progress,
#progressDone {
  margin: 0 2% 0 0;
  width: 80%;
  height: 22px;
  display: flex;
  border-radius: 50px;
  background-color: var(--grey);
  border: #003769 solid 1.5px;
}

.surveyForm {
  top: 0%;
  width: 90%;
}

.formField {
  padding-bottom: 50px;
}

/* Entire form */
.questBody {
  height: 85%;
  width: 95%;
  margin: 5%;
  overflow-y: scroll;
}

.ui.button {
  margin-left: 20px;
  text-align: center;
  font-size: 1em;
}

.ui.message {
  padding: 0.75em;
  margin-top: 0;
  margin-bottom: 5%;
}

.headerMain {
  height: 75px;
  opacity: 0.91;
  background-image: linear-gradient(138deg, #00aae1, #003769);
}

.headerTitle {
  float: left;
  padding-top: 0.4em;
  padding-left: 3%;
  font-family: CustomFont;
  font-size: 2.5em;
  color: white;
}

@font-face {
  font-family: CustomFont;
  src: url(../../assets/fonts/sofiaprolight-webfont.woff);
}

.exitMessage {
  top: 50%;
  text-align: center;
  position: absolute;
  width: 100%;
}

.questDiv2 {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
}

.questFillInfo {
  height: 100%;
  top: 50%;
  text-align: center;
}

.informationForm {
  margin: auto;
}

.graphFullscreenTint {
  backdrop-filter: blur(6px);
  width: 100%;
  height: 100vh;
  opacity: 0.8;
  background: #f9f9f9;
}

.resultReturnBtn {
  position: absolute;
  left: 44.8%;
  top: 80%;
}

.resultDownloadBtn {
  position: absolute;
  padding: 5%;
}

.resultFullscreen {
  position: absolute;
  width: 100%;
  height: 100%;
}

.resultQuestionWrap {
  position: absolute;
  width: 100%;
  height: 100%;
}

.resultGraphs {
  width: 100%;
  height: 100%;
  padding-left: 14%;
  padding-top: 4%;
}

.ui.button.graphExitBtn {
  width: 100%;
  border-radius: 5%;
}

.messageBorder {
  background: #f9f9f9;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
  text-align: center;
  top: 15%;
  position: absolute;
}

.messageText {
  padding: 1em;
  font-size: 1.5em;
}

.messageButton {
  padding-bottom: 1em;
}

.verical-center {
  background-color: red;
}
