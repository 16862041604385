.headerMain{
    /* position: fixed;
    width: 100%;
    height: 12%;
    top: 0; */
}

.headerLogo{
    /* float: left;
    height: 90%;
    padding-top: 0.8em;
    padding-left: 0.7em; */
}

.headerTitle{
    float: left;
    padding-top: 0.4em;
    padding-left: 3%;
    font-family: CustomFont;
    font-size: 2.5em;
    color: white;
}

@font-face{
    font-family: CustomFont;
    src: url(../assets/fonts/sofiaprolight-webfont.woff);
}

.avatarLogo{
    margin-right: 1%;
    margin-top: 1%;
    float: right;
    padding-top: 1.5em;
    padding-right: 1.5em;
    width: 100px;
    cursor: pointer;
}

.avatarHub{
    float: right;
    z-index: 1000;
    width: 380px;
    height: 215px;
    margin-right: 1%;
    margin-top: 1%;
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border-radius: 20px;
    box-shadow: -3px 3px 6px #00000029;
}

#headerBtn{
    align-self: flex-end;
    font-family: CustomFont;
    font-weight: bold;
    text-align: center;
    color: white;
    background: #003769 0% 0% no-repeat padding-box;
    border-radius: var(--buttonRadius);
    margin-top: 0.5em;
    height: 2em;
    top: 0;
    width: 10em;

    opacity: 1;
    padding: 0;
}

.infoHub{
    margin-top: 3%;
    margin-left: 10%; 
}

