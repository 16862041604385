.surveyView {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;

  width: 60%;
  height: 70%;

  margin-left: 7%;
  position: absolute;
  bottom: 3%;
  padding: 1em;
  overflow-y: auto;
}

.surveyGraph {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
  display: flex;
  width: 72%;
  height: 80%;

  margin-left: 2%;
  position: absolute;
  bottom: 3%;
  padding: 1em;
  overflow-y: auto;
}

.newSurveyForm {
  top: 0%;
  width: 50%;
  margin: auto;
}

.filterView {
  background: #f9f9f9;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
  text-align: center;
  top: 25%;
  left: 25%;
  position: absolute;
  width: 50%;
  height: 50%;
}

.filterHeader {
  width: 100%;
  height: 15%;
  text-align: center;
  font-size: 3em;
}

.filterBody {
  width: 100%;
  height: 85%;
  padding: 1% 5% 5% 5%;
}

.filterBodyButton {
  padding: 1%;
}

.imgWrap {
  position: absolute;
  width: 100%;
}

.imgWrap2 {
  position: absolute;
  height: 100%;
}

.filter {
  position: absolute;
}
.graphIMG,
.graphIMG2 {
  padding: 2%;
  width: 100%;
  height: 100%;
}

.surveyScatter,
.surveyLine {
  position: relative;
  width: 300px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.graphHeader {
  margin-left: 10%;
  width: 90%;
  margin-top: 1%;
  position: absolute;
}

.graphTitle {
  height: 6%;
  font-size: 1em;
  padding-left: 3%;
  padding-right: 3%;
}

.graph {
  margin-top: 1em;
  margin-bottom: 2em;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .surveyScatter,
  .surveyLine {
    position: relative;
    width: 400px;
    height: 400px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .surveyScatter,
  .surveyLine {
    position: relative;
    width: 500px;
    height: 500px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .surveyScatter,
  .surveyLine {
    width: 400px;
    height: 400px;
  }
  .graphHeader {
    margin-top: 2%;
  }
  .graphTitle {
    font-size: 2em;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .surveyScatter,
  .surveyLine {
    width: 500px;
    height: 500px;
  }
}
/* Turn off display flex to stop img from stretching. */
@media (max-width: 1420px) {
  .surveyGraph {
    display: block;
  }
}

.scatterWrapper {
  position: absolute;
  width: 84%;
  height: 84%;
  margin-left: 8%;
  margin-top: 8%;
}

.lineWrapper {
  position: absolute;
  width: 84%;
  height: 75%;
  margin-left: 8%;
  margin-top: 8%;
}

.graphFullscreenBtn {
  float: right;
}

.graphFullscreen {
  position: relative;
  width: 100%;
  height: 100%;
}

.graphFullscreenWrap {
  padding: 1.5em 1em 1em 1em;
  transform-origin: 0 0;
  transform: scaleX(1.2) scaleY(1.2);
  margin-left: 28%;
}

.graphFullscreenTint {
  position: absolute;
  backdrop-filter: blur(6px);
  width: 100%;
  height: 100vh;
  opacity: 0.8;
  background: #f9f9f9;
}

.graphFullscreenReturnBtn {
  margin-top: 4em;
  margin-left: 8em;
  position: absolute;
  width: 100%;
}

.ui.button.graphRtnBtn {
  transform: scaleX(1) scaleY(1);
}

.ui.button.buttonStyle {
  margin-left: 2%;
  padding: 10% 5% 18% 5%;
}

.graphLegend {
  float: left;
  width: 40%;
  margin-top: 2%;
}

.legendRed {
  background: #eb1e23;
  border-radius: 30px;
  width: 1.25em;
  height: 1.25em;
  border: 0.7px solid;
  float: left;
}

.legendRFont {
  float: left;
  padding-left: 2%;
}

.legendOFont {
  padding-left: 2%;
  float: right;
}

.legendOrange {
  background: #f48800;
  border: 0.7px solid;
  border-radius: 30px;
  width: 0.85em;
  height: 0.85em;
  float: right;
  margin-top: 2%;
  margin-left: 5%;
}

.surveyTitle {
  font-size: 20px;
  text-decoration: underline;
  text-align: center;
}

.surveySelection {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: -3px 3px 6px #00000029;
  border-radius: 30px 0px 0px 0px;
  text-align: center;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 25%;
  height: 73%;
  padding: 15px;
}

.surveyButtonNav {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;

  position: fixed;
  top: 18%;
  margin-left: 15%;
}
