:root {
  --orange: #e95027;
  --buttonRadius: 22px;
}

.app {
  text-align: center;
}

.form {
  top: 30%;
  min-width: 30%;
  max-width: 50%;
}

.buttonG {
  float: right;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

#form {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  top: 0%;
  height: 100%;
}

.codeFormLabel {
  font-size: 3em;
}

.leftSide {
  height: 100vh;
  opacity: 0.91;
  background-image: linear-gradient(138deg, #00aae1, #003769);
}

.rightSide {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  height: 100vh;
}

.logoTextA {
  font-family: CustomFont;
  font-size: 2.75em;
  color: white;
  text-align: center;
}

#formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-bottom: 7em;
}

#labelContainer {
  width: 100%;
  font-size: 1em;
}
#inputContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  font-size: 1em;
}

#inputContainer .inputField {
  background-color: #ffffff00; 
  border-width: 0;
  border-radius: 0%;
  border-bottom: 1px solid #003769;
  font-size: 1em;
  height: 1.65em;
  padding-top: 25px;
}

.imageKey {
  margin-right: 15px;
}

#loginButton {
  align-self: flex-end;
  font-family: CustomFont;
  font-weight: bold;
  font-size: 1.1em;
  text-align: center;
  color: white;
  background: #003769 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 2em;
  width: 8em;
  margin-top: 1em;
  margin-right: 1em;
  top: 0;
  padding: 0;
  float: right;
  z-index: 999;
}

#exitButton {
  align-self: flex-end;
  font-family: CustomFont;
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
  color: white;
  background: #c00c0c 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 2em;
  width: 8em;
  margin-top: 1em;
  margin-right: 1em;
  top: 0;
  padding: 0;
  float: right;
}

#submitButton {
  align-self: flex-end;
  font-family: CustomFont;
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
  color: white;
  background: #003769 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 2em;
  width: 18em;
  padding: 0;
  margin-top: .75rem;
}

#noCodeButton {
  align-self: flex-end;
  font-family: CustomFont;
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
  color: white;
  background: #003769 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 2em;
  width: 18em;
  padding: 0;
  margin-bottom: 3em;
}

#continueButton {
  background: #ffffff00; /*transparent*/
  text-align: center;
  margin-top: 10px;
  padding: 0;
}

.loginSlide {
  position: absolute;
  left: -500px;
  height: 100%;
  background: white;
  overflow-y: hidden;
  overflow-x: hidden;
}

.btnSubmitWrap {
  width: 100%;
  height: 30%;
}

.btnSubmit {
  width: 100%;
}

@font-face {
  font-family: CustomFont;
  src: url(../../assets/fonts/sofiaprolight-webfont.woff);
}

.gradient {
  opacity: 0.91;
  background-image: linear-gradient(138deg, #00aae1, #003769);
}

.row {
  margin: 0;
}

@media only screen and (max-width: 992px) {
  .leftSide {
    height: 10%;
  }
  .rightSide {
    height: 90%;
  }
}
